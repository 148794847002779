@keyframes card-shift {
  0% {
    -webkit-transform: translate3d(-30px, 0, 0);
    transform: translate3d(-30px, 0, 0);
  }
  25% {
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  75% {
    -webkit-transform: translate3d(-30px, 0, 0);
    transform: translate3d(-30px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-30px, 0, 0);
    transform: translate3d(-30px, 0, 0);
  }
}

.bg-image {
  height: calc(100% + 30px);
  width: calc(100% + 30px);
  -webkit-animation: card-shift 12s ease-in-out infinite;
  animation: card-shift 12s ease-in-out infinite;
  position: absolute;
  z-index: -3;
  // height: 600px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bg-image img {
  object-fit: fill;
}
