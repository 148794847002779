.image {
  background-image: url("../img/me.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: spin 2s linear infinite;
  border-radius: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
